<template>
  <div class="icon-tile">
    <aspect-ratio tag="a" ratio="2x1" class="icon-tile-tile" v-bind="$attrs">
      <lazy-icon :icon="iconName" />
      <span aria-hidden="true"><slot /></span>
    </aspect-ratio>

    <div class="icon-tile-content">
      <slot />
    </div>
  </div>
</template>

<script>
import AspectRatio from '@/components/private/AspectRatio'

export default {
  name: 'IconTile',
  inheritAttrs: false,
  components: {
    AspectRatio
  },
  props: {
    iconName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
// base

$icontile-gap: $grid-gutter-width !default;

$icontile-tile-gap: $paragraph-margin-bottom !default;
$icontile-tile-bg: $gray-100 !default;
$icontile-tile-color: $primary !default;

$icontile-tile-icon-size: $spacer * 7.5 !default;

// news-teaser

$icontile-tile-content-tag-gap: $spacer * 0.2 !default;
$icontile-tile-content-tag-font-size: $font-size-sm !default;
$icontile-tile-content-tag-color: $primary !default;

// popular-categories

$icontile-popularcategories-gap: 0 !default;

$icontile-popularcategories-tile-gap: 0 !default;
$icontile-popularcategories-tile-bg: $white !default;
$icontile-popularcategories-tile-color: $brown !default;
$icontile-popularcategories-tile-transition: $transition-base !default;

$icontile-popularcategories-tile-hover-box-shadow: map-get($shadows, 'image') !default;
$icontile-popularcategories-tile-hover-transform: scale(1.05) !default;
$icontile-popularcategories-tile-hover-color: $primary !default;

$icontile-popularcategories-tile-icon-gap: $spacer * 0.2 !default;
$icontile-popularcategories-tile-icon-size: $spacer * 2.5 !default;
$icontile-popularcategories-tile-icon-color: $brown !default;

.icon-tile {
  display: block;
  margin-bottom: $icontile-gap;
  width: 100%;
  height: 100%;

  .icon-tile-tile {
    margin-bottom: $icontile-tile-gap;
    background: no-repeat center center $icontile-tile-bg;
    background-size: cover;
    color: $icontile-tile-color;

    > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: inherit;

      > .bi {
        display: block;
        width: $icontile-tile-icon-size;
        height: $icontile-tile-icon-size;
        color: inherit;
      }

      > span {
        display: none;
      }
    }
  }

  .icon-tile-content {
    .content-tag {
      margin-bottom: $icontile-tile-content-tag-gap;
      font-size: $icontile-tile-content-tag-font-size;
      color: $icontile-tile-content-tag-color;
    }
  }

  .icon-tile-grid.popularcategories & {
    margin: $icontile-popularcategories-gap;

    .icon-tile-tile {
      position: relative;
      margin-bottom: $icontile-popularcategories-tile-gap;
      background-color: $icontile-popularcategories-tile-bg;
      color: $icontile-popularcategories-tile-color;
      transition: $icontile-popularcategories-tile-transition;
      z-index: 1;

      > span {
        > .bi {
          margin: 0 auto $icontile-popularcategories-tile-icon-gap;
          width: $icontile-popularcategories-tile-icon-size;
          height: $icontile-popularcategories-tile-icon-size;
        }

        > span {
          display: block;
        }
      }

      &:hover {
        box-shadow: $icontile-popularcategories-tile-hover-box-shadow;
        color: $icontile-popularcategories-tile-hover-color;
        transform: $icontile-popularcategories-tile-hover-transform;
        z-index: 2;
      }
    }

    .icon-tile-content {
      @include sr-only();
    }
  }
}
</style>
